<template>
  <v-container>
    <v-card outlined :loading="loadingServicos">
      <v-card-title class="pa-4 font-weight-bold accent">
        <v-icon left color="primary">mdi-briefcase-variant</v-icon> Serviços
        Empresa Empresa
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text v-if="cliente_servicos.length" class="py-4">
        <div>
          <v-chip
            v-for="(servico, index) in cliente_servicos"
            :key="index"
            class="ma-1"
            label
          >
            <v-icon left>mdi-account</v-icon>
            {{ servico.servico }}
          </v-chip>
        </div>
      </v-card-text>
      <v-card-text v-else class="pa-4">
        Nenhum serviço vinculado.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { fetchClienteServicos } from "@/api/cliente/cliente_servicos.js";
export default {
  name: "Servicos",

  props: {
    cliente_id: {
      type: Number,
    },
  },

  data() {
    return {
      cliente_servicos: [],
      loadingServicos: false,
    };
  },

  methods: {
    getClienteServicos() {
      this.loadingServicos = true;
      return fetchClienteServicos(this.cliente_id)
        .then((response) => {
          this.cliente_servicos = response;
          this.loadingServicos = false;
        })
        .catch(() => {
          this.loadingServicos = false;
        });
    },
  },

  async mounted() {
    await this.getClienteServicos();
  },
};
</script>

<style></style>
